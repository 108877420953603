<template>
  <div class="factory-device-product">
    <el-row>
      <el-form :inline="true" size="mini" label-width="auto">
        <el-col :span="6">
          <el-form-item label="产品搜索:">
            <el-input placeholder="请输入产品名称" v-model="pagination.name"
                      @change="loadSearchProduct" clearable
                      prefix-icon="el-icon-search"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="3">
          <el-button type="primary" size="mini" plain @click="handleProductCreate">新增</el-button>
        </el-col>
      </el-form>
    </el-row>
    <el-row class="factory-device-products" :gutter="20" type="flex">
      <el-col :span="6" v-for="(item, index) in products" :key="index" @click.native="handleProductDetail(item)">
        <div title="查看设备" class="product-item">
          <el-row class="product clearfix">
            <el-col class="product-info" :span="22">
              <p class="info-item">
                <span class="label">产品编号:</span>
                <span class="content">{{item.uuid}}</span>
              </p>
              <p class="info-item">
                <span class="label">产品名称:</span>
                <span class="content product-name">{{item.name}}</span>
              </p>
              <p class="info-item">
                <span class="label">产品备注:</span>
                <span class="content">{{item.remark}}</span>
              </p>
              <p class="info-item">
                <span class="label">已注册设备:</span>
                <span class="content">{{item.deviceNumber | I1000}}</span>
              </p>
            </el-col>
            <el-col class="operate" :span="2">
              <div class="publish" title="更多信息"><i class="iconfont icongengduo"></i></div>
              <div class="publish" title="删除产品" v-if="item.deviceNumber === 0"
                   @click="handleProductDelete(item.uuid, index)" @click.stop.prevent>
                <i class="iconfont iconshanchu" ></i>
              </div>
              <div class="publish" title="查看密钥" @click="getProductSecret(item)" @click.stop.prevent>
                <i class="iconfont iconicon-view"></i>
              </div>

            </el-col>
          </el-row>
          <p class="info-item">
            <span class="label">Key:</span>
            <span class="content">{{item.productKey}}</span>
          </p>
          <p class="info-item">
            <span class="label">密钥:</span>
            <span class="content">{{item.productSecret}}</span>
          </p>
        </div>
      </el-col>
    </el-row>
    <el-pagination
      @current-change="handleCurrentChange"
      class="pagination"
      :current-page="pagination.curPage"
      :page-size="pagination.limit"
      layout="total, prev, pager, next, jumper"
      :total="pagination.total">
    </el-pagination>
    <add-product v-show="addProductVisible" @close-product="closeProduct" @fill-product="fillProduct"
                 ref="productCreate"></add-product>


  </div>
</template>

<script>
  import AddProduct from './addProduct.vue'
  import {
    productUnion,
    productList,
    productSecret,
    productDelete
  } from '@/api/factory/research/product'

  import {mapActions} from 'vuex'

  export default {
    computed: {},
    data() {
      return {
        addProductVisible: false,
        pagination: {
          name: '',
          start: 0,
          curPage: 1,
          limit: 8,
          total: 0,
        },
        products: []
      }
    },
    mounted: function () {
      this.loadProduct()
    },
    methods: {
      ...mapActions(["clearProductNames"]),
      fillProduct(product) {
        this.products.unshift(product)
        this.clearProductNames()
        this.pagination.total += 1
      },

      closeProduct() {
        this.addProductVisible = false
      },

      handleCurrentChange(value) {
        this.pagination.curPage = value
        this.pagination.start = (value - 1) * this.pagination.limit
        productList(this.pagination).then(res => {
          this.products = res.data
        })
      },
      handleProductDetail(product) {
        this.$router.push({name: 'system.product.detail', params:product})
      },

      loadSearchProduct() {
        this.pagination.start = 0
        this.pagination.curPage = 1
        this.loadProduct()
      },
      handleProductDelete(uuid, index) {
        this.$confirm('此操作将会删除产品, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          productDelete(uuid).then(res => {
            this.$message.success('删除成功')
            this.products.splice(index, 1)
            this.pagination.total -= 1
          })
        })

        //
      },

      loadProduct() {
        let vue = this
        productUnion(this.pagination).then(res => {
          if (res !== null && res.code === 0) {
            vue.products = res.data.list || []
            vue.pagination.total = parseInt(res.data.total)
          }
        })
      },

      getProductSecret(product) {
        let vue = this
        productSecret(product.uuid).then(res => {
          vue.$alert(res.data, product.name, {
            confirmButtonText: '确定',
          });
        })
      },


      handleProductCreate() {
        this.$refs.productCreate.showCreate()
        this.addProductVisible = true
      }
    },
    components: {
      AddProduct
    }
  }
</script>

<style lang="scss">

  .factory-device-product {
    min-height: 608px;
    border-radius: $border-radius;
    @media (max-height: 768px) {
      min-height: 576px;
    }
    padding: $padding;
    display: flex;
    flex-direction: column;

    .factory-device-product-title {
      margin-bottom: $padding;

      .factory-device-product-title-filter {
        display: flex;
        justify-content: center;
        align-items: center;

        .label {
          display: inline-block;
          width: 5rem;
          font-size: .6rem;
          text-align: right;
        }
      }

      .btn-add {
        cursor: pointer;
      }

    }

    .factory-device-products {
      flex-flow: row wrap;
      align-content: flex-start;

      .product-item {
        padding: $padding;
        box-shadow: $-box-shadow;
        border-radius: .2rem;
        font-size: 12px;
        cursor: pointer;

        .content {
          display: inline-block;
          padding-left: $padding-4;
        }

        .info-item {
        }

        .product {
          .product-name {
            color: $btn_color;
          }

          .operate {
            .publish {
              cursor: pointer;
            }

            .publish:hover {
              color: $_theme_color;
            }
          }
        }
      }
    }
  }

</style>
