<template>
  <div class="factory-product-add">
    <c-dialog class="dialog" :visible.sync="addProductVisible" :width="'36rem'" :height="'24rem'" title="新增产品">
      <el-form class="create-product-dialog" label-width="100px">
        <el-form-item label="产品名称">
          <el-input v-model="addProductInfo.name" placeholder="请输入产品名称"></el-input>
        </el-form-item>
        <el-form-item label="产品备注">
          <el-input v-model="addProductInfo.remark" placeholder="请输入产品备注"></el-input>
        </el-form-item>
        <el-form-item label="产品排序">
          <el-input v-model="addProductInfo.orderCond" type="number"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button class="success" plain @click="AddProduct">提交</el-button>
        </el-form-item>
      </el-form>
    </c-dialog>
  </div>
</template>

<script>
  import {productCreate} from '@/api/factory/research/product';
  import CDialog from '@/components/Dialog'

  export default {
    name: '',
    components: {
      CDialog
    },
    props: {
      visible: {
        type: Boolean,
        default: false
      },

    },
    mounted: function () {
    },
    watch: {
      addProductVisible: function (newVal) {
        this.$emit('close-product')
      }
    },
    data: function () {
      return {
        addProductVisible: true,
        addProductInfo: {
          name: '',
          remark: '',
          orderCond: 0,
        },
      }
    },
    methods: {
      showCreate() {
        this.addProductVisible = true
      },
      AddProduct() {
        if (this.addProductInfo.name === ``) {
          this.$message.error('请输入产品名称')
          return
        }

        let product = {
          name: this.addProductInfo.name,
          remark: this.addProductInfo.remark,
          orderCond: parseInt(this.addProductInfo.orderCond),
        }
        let vue = this
        productCreate(product).then(res => {
          if (res !== null && res.code === 0) {
            vue.$emit('fill-product', res.data)
            vue.addProductInfo = {
              name: '',
              remark: '',
              orderCond: 0,
            }
            vue.$emit('close-product')
          }
        })

      },

    },
  }
</script>

<style lang="scss">
  .factory-product-add {
    .dialog {
      .create-protocol-dialog {
        padding: 0 $padding;

        .el-form-item__label {

        }


        .el-textarea__inner {
          font-size: .6rem;
        }


        .protocol-input {
          p {
            margin-top: 5px;
            font-size: 12px;
            color: #999999;
          }
        }
      }
    }
  }
</style>
